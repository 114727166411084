<template>
  <div>
    <div v-if="subject">
      <div class="row">
        <div class="col-4">
          <a-input-number
            v-on:keyup.enter="mark == null ? addMark() : updateMark()"
            :min="0"
            :max="20"
            size="small"
            style="height: 25px; width: 57px; margin: 1px"
            v-if="mark == null || edit"
            v-model="newMark"
          />
          <span v-else> {{ mark }} / 20 </span>
        </div>
        <div class="col-8" v-if="mark != null && !edit">
          <a-button
            @click="
              () => {
                edit = true;
              }
            "
            type="primary"
            icon="edit"
          >
            {{ $t("action.modifier") }}
          </a-button>

          <a-popconfirm
            :title="$t('bulletin.deleteMark')"
            @confirm="() => onDelete(record)"
          >
            <a-button icon="delete" type="danger" class="ml-2">
              {{ $t("action.supprimer") }}
            </a-button>
          </a-popconfirm>
        </div>
        <div class="col-8" v-if="mark == null">
          <a-button icon="check" type="primary" @click="addMark">
            {{ $t("action.enregistrer") }}
          </a-button>
        </div>
        <div class="col-8 success" v-if="edit">
          <a-button
            icon="check"
            class="mr-2"
            type="primary"
            @click="updateMark"
          >
            {{ $t("action.enregistrer") }}
          </a-button>
          <a-button
            icon="close"
            @click="
              () => {
                edit = false;
              }
            "
            type="default"
            >{{ $t("action.annuler") }}
          </a-button>
        </div>
      </div>
    </div>
    <div v-else>{{ $t("choisir.matiere") }}</div>
  </div>
</template>
<script>
import apiClient from "@/services/axios";

export default {
  props: ["id", "subject", "trimester", "classroom", "studentMarks"],
  async created() {
    const studentMark = this.studentMarks.find(
      (s) => s.subject == this.subject
    );
    if (studentMark) {
      this.mark = studentMark.mark;
      this.markId = studentMark._id;
      this.newMark = studentMark.mark;
    }
  },
  data() {
    return {
      notes: [],
      newMark: null,
      mark: null,
      edit: false,
      markId: null,
    };
  },
  methods: {
    addMark(alert = true) {
      if (this.newMark == null)
        return this.$message.error(this.$t("error.impoEnregistrerVide"));
      if (this.newMark > 20 || this.newMark < 0)
        return this.$message.error(this.$t("error.invalidNote"));
      apiClient
        .put("/mark/", {
          data: {
            subject: this.subject,
            student: this.id,
            mark: this.newMark,
            trimester: this.trimester,
            classroom: this.classroom,
          },
        })
        .then(({ data }) => {
          this.mark = data.mark;
          this.markId = data._id;
          if (alert) {
            this.$emit("updateBulletin");
            this.$message.success(this.$t("success.noteEnregistre"));
          }
        });
    },
    updateMark(alert = true) {
      if (!this.newMark)
        return this.$message.error(this.$t("error.impoEnregistrerVide"));
      if (this.newMark > 20 || this.newMark < 0)
        return this.$message.error(this.$t("error.invalidNote"));
      apiClient
        .patch("/mark/" + this.markId, {
          data: {
            mark: this.newMark,
          },
        })
        .then(({ data }) => {
          this.edit = false;
          this.mark = this.newMark;
          if (alert) {
            this.$emit("updateBulletin");
            this.$message.success(this.$t("success.noteEnregistre"));
          }
        });
    },
    onDelete() {
      apiClient
        .delete("/mark/" + this.markId)
        .then((r) => {
          this.mark = null;
          this.markId = null;
          this.newMark = null;
          this.edit=false
          this.$emit("updateBulletin");
          this.$message.success(this.$t("bulletin.markDeletedSucc"));
        })
        .catch((r) => {
          this.$message.error(this.$t("bulletin.markDeletedFail"));
        });
    },
  },
  watch: {
    studentMarks: function (value) {
      this.mark = null;
      this.newMark = null;
      this.edit = false;

      const studentMark = this.studentMarks.find(
        (s) => s.subject == this.subject
      );
      if (studentMark) {
        this.mark = studentMark.mark;
        this.markId = studentMark._id;
        this.newMark = studentMark.mark;
      }
    },
    subject: function (value) {
      this.mark = null;
      this.newMark = null;
      this.edit = false;

      const studentMark = this.studentMarks.find(
        (s) => s.subject == this.subject
      );
      if (studentMark) {
        this.mark = studentMark.mark;
        this.markId = studentMark._id;
        this.newMark = studentMark.mark;
      }
    },
  },
};
</script>

<style scoped>
.success .ant-btn-primary {
  background: #8bc34a !important;
  border-color: #8bc34a !important;
}
</style>
